<template>
  <div>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>
          <b-col sm="4">
            <div>
              <b-avatar
                ref="previewEl"
                :src="model.logo"
                :text="model.logo || avatarText(model.name)"
                size="auto"
                rounded
                @click="$refs.refInputEl.click()"
              />
              <b-button
                class="mt-1"
                variant="primary"
                @click="$refs.refInputEl.click()"
              >
                <input
                  ref="refInputEl"
                  type="file"
                  accept="image/*"
                  class="d-none"
                  @input="renderImage"
                >
                <span class="d-none d-sm-inline">Atualizar</span>
                <feather-icon
                  icon="EditIcon"
                  class="d-inline d-sm-none"
                />
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <i2-form-input
              v-model="model.document"
              v-maska
              label="CNPJ"
              rules="required|cpfCnpj"
              data-maska="['##.###.###/####-##']"
              name="document"
              @input="getCompanyInformation"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="12"
          >
            <i2-form-input
              v-model="model.name"
              rules="required"
              label="Nome"
              placeholder="informe o nome do cliente"
              name="name"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="12"
          >
            <i2-form-input
              v-model="model.domain"
              label="Dominio da aplicaçao"
              placeholder="informe o dominio na aplicação "
              name="name"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <i2-form-vue-select
              v-model="model.managers"
              :multiple="true"
              :options="userList"
              name="userList"
              label="Responsáveis"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <i2-form-input
              v-model="model.corporate_name"
              label="Razão Social"
              name="corporate_name"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <i2-form-address-input v-model="model.address" :value="model.address"/>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <div>
      <b-row>
        <b-col
          sm="12"
          md="12"
          class="text-right"
        >

          <!-- Form Actions -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="button"
            class="mr-2"
            @click="onSubmit"
          >

            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Salvar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            type="button"
            @click="onCancel"
          >

            <font-awesome-icon
              prefix="far"
              :icon="['far', 'times-circle']"
              class="mr-50"
            />
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { alphaNum, email, required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import I2FormAddressInput from '@/components/i2/forms/elements/I2FormAddressInput.vue'
import { vMaska } from 'maska'

export default {
  name: 'ClientForm',
  directives: {
    Ripple,
    maska: vMaska,
  },
  components: {
    I2FormAddressInput,
    ValidationObserver,
  },
  props: {
    modelUuid: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      typesIndexes: [],
      userList: [],
      model: {
        name: '',
        domain: '',
        document: '',
        corporate_name: '',
        managers: [],
        address: {},
        file: '',
        logo: require('@/assets/images/logo/no-logo.png'),
        uuid: null,
      },
    }
  },
  watch: {
    modelUuid(data) {
      if (data === '') {
        return
      }
      this.getUsers(data)
      this.getData(data)
    },
  },
  methods: {
    avatarText(value) {
      if (!value) {
        return ''
      }
      const nameArray = value.split(' ')
      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          const data = new FormData()
          const managers = this.model.managers.map(item => item.value)
          data.append('name', this.model.name)
          data.append('domain', this.model?.domain || '')
          data.append('document', this.model.document)
          data.append('corporate_name', this.model.corporate_name)
          data.append('address[postcode]', this.model.address?.postcode)
          data.append('address[address]', this.model.address?.address)
          data.append('address[city]', this.model.address?.city)
          data.append('address[neighborhood]', this.model.address?.neighborhood)
          data.append('address[state]', this.model.address?.state)
          data.append('address[city_code]', this.model.address?.city_code)
          data.append('address[number]', this.model.address?.number)
          data.append('address[complement]', this.model.address?.complement)

          if (this.$refs.refInputEl.files.length === 1) {
            data.append('logo', this.$refs.refInputEl.files[0])
          }
          managers.forEach(item => {
            data.append('managers[]', item)
          })
          if (this.model.uuid) {
            response = await this.$http.post(`clients/${this.model.uuid}?_method=PUT`, data)
          } else {
            response = await this.$http.post('clients', data)
          }
          if (response.error) {
            this.$notify.error(response.error_message)
            return
          }
          this.$notify.success('Cliente salvo com sucesso.')
          this.$refs.refFormObserver.reset()
          this.model = {
            name: '',
            uuid: null,
          }
          this.$emit('onModelSave', true)
        }
      })
    },
    async getUsers(uuid) {
      const response = await this.$http.get(`companies/${uuid}/users`)
      this.userList = []
      this.userList = response.map(item => ({
        text: item.name,
        value: item.uuid,
      }))
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.model = { ...this.blankUser }
      this.$refs.refFormObserver.reset()
    },
    async getData(uuid) {
      const response = await this.$http.get(`clients/${uuid}`)
      if (!response.error) {
        this.model = response
        this.model.managers = response.managers.map(user => this.userList.find(us => us.value === user.uuid))
      }
    },
    renderImage() {
      const file = this.$refs.refInputEl.files[0]
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.model.logo = reader.result
          setTimeout(() => {
            this.$nextTick(() => { this.$forceUpdate() })
          }, 200)
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    getCompanyInformation() {
      if (this.model.document.length < 18) {
        return
      }
      this.$http.get(`company-search?document=${this.model.document}`).then(response => {
        this.model.name = response.name
        this.model.corporate_name = response.corporate_name
        this.model.address = response.address
      })
    },
  },
}
</script>

<style >
.ql-editor{
  min-height:200px;
}
</style>
